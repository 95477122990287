import React from "react"
import { graphql, Link } from "gatsby"
import MyHelmet from "../components/MyHelmet"
import { Container } from "../components/Sections"
import {
  ArrowLeft,
  ArrowRight,
} from "../components/Icons"
import InfoPageTemplate from "./InfoPageTemplate"

const InfoPage = ({ data, pageContext }) => {
  const { next, previous } = pageContext
  const { markdownRemark: info } = data
  const { frontmatter: fm } = info

  return (
    <>
      <MyHelmet title={fm.title} description={info.excerpt} />
      <InfoPageTemplate
        title={fm.title}
        date={fm.date}
        image={fm.image}
        body={info.html}
        icon={fm.icon}
      />

      {/* Links to previous and next info */}
      <Container>
        <div className="sm:flex sm:justify-between sm:items-center sm:gap-4 border-t py-4">
          {previous && previous.frontmatter.templateKey === "info-page" ? (
            <Link to={previous.fields.slug} className="group">
              <div className="flex items-center gap-x-2 text-gray-500">
                <ArrowLeft />
                Zurück
              </div>
              <div className="font-bold text-lg text-gray-700 dark:text-gray-300 group-hover:underline">
                {previous.frontmatter.title}
              </div>
            </Link>
          ) : (
            <div />
          )}
          {next && next.frontmatter.templateKey === "info-page" ? (
            <div className="mt-6 sm:mt-0">
              <Link to={next.fields.slug} className="group sm:text-right">
                <div className="flex items-center gap-x-2 text-gray-500 sm:justify-end">
                  Weiter
                  <ArrowRight />
                </div>
                <div className="font-bold text-lg text-gray-700 dark:text-gray-300 group-hover:underline">
                  {next.frontmatter.title}
                </div>
              </Link>
            </div>
          ) : (
            <div />
          )}
        </div>
      </Container>
    </>
  )
}

export default InfoPage

export const infoQuery = graphql`
  query InfoPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      excerpt
      frontmatter {
        title
        date(formatString: "DD. MMMM YYYY", locale: "de-DE")
        icon
        image {
          childImageSharp {
            gatsbyImageData(width: 640, placeholder: BLURRED)
          }
        }
      }
    }
  }
`
