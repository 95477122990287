import React from "react"
import { Helmet } from "react-helmet"
import Content from "../components/Content"
import { InfoHeader } from "../components/Header"
import { TextImageSplit } from "../components/Sections"

function InfoPageTemplate({ title, date, icon, image, body }) {
  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      <InfoHeader heading={title}/>
      <TextImageSplit image={image} imageFirst={true} split="sm">
        <div className="flex items-baseline gap-x-2 justify-between mb-6 ">
          <span className="uppercase text-primary-700 dark:text-gray-200 font-bold text-xs tracking-wide">
            {icon}
          </span>
          <span className="text-sm text-gray-600 dark:text-gray-300 sm:mr-2">{date}</span>
        </div>
        <Content html={body}/>
      </TextImageSplit>
    </>
  )
}

export default InfoPageTemplate
